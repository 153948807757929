import React, { Component } from "react";
import Logo from "./assets/img/logo.webp";
import { Link } from "react-router-dom";
import "./assets/css/navbar.css";
import {Image } from "react-bootstrap";

class Footer extends Component {
  render() {
    const footerbgColor = {
      backgroundColor: "black",
      marginTop: "30px",
    };

    const footerTextSize = {
      fontSize: "36px",
    };

    return (
      <div className="fluid" id="contact">
        <footer className="card-body footer-text" style={footerbgColor}>
          <div className="row">
            <div className="col-sm-4">
              <Image src={Logo} alt="Logo-img" width="110px" height="90px"/>
              <p className="card-text" style={{color:"grey"}}>
              We are one of the leading stockist with multiple warehouses and showrooms.
              </p>
            </div>
            <div className="col-sm-4">
              <h4 className="card-title">
                <b>Quick Links</b>
              </h4>
                  <div className="card-text ">
                    <ul>
                      <li>
                    <Link className="link-color middle" to="/steel">
                          Steel
                        </Link>
                        </li>
                        <li>
                        <Link className="link-color middle" to="/sheets">
                          Sheets
                        </Link>
                        </li>
                        <li>
                        <Link className="link-color middle" to="/cement">
                          Cement
                        </Link>
                        </li>
                        <li>
                        <Link className="link-color middle" to="/tiles-S">
                          Tiles & Saniteries
                        </Link>
                      </li>                  
                      <li>
                        <Link className="link-color middle" to="/hardware">
                          Hardwares
                        </Link>
                      </li>
                    </ul>
                  </div>
            </div>
            <div className="col-sm-4">
              <h4>
                <b>Contact</b>
              </h4>
              <div className="card-text">
                M/S SURABHI STEEL HOUSE Near Bhaghirata Petrol Pump,Banahatti
                Road Rabakavi - 587314
                <br />
                Cell - 9448864599, 9448839807, 9902833832
                <br />
                GST : 29ABNFS1031K1ZX
              </div>
              <p>
                <a href="https://facebook.com/">
                  <i
                    className="fa fa-facebook-square link-color"
                    style={footerTextSize}
                  ></i>
                </a>
                &emsp;
                <a href="https://linkedin.com/">
                  <i
                    className="fa fa-linkedin link-color"
                    style={footerTextSize}
                  ></i>
                </a>
                &emsp;
                <a href="https://twitter.com">
                  <i
                    className="fa fa-twitter link-color"
                    style={footerTextSize}
                  ></i>
                </a>
                &emsp;
              </p>
            </div>
          </div>
          <div>
            &copy; Surabhi Steel House &middot;{" "}
            <a className="link-color middle" href="/">
              Privacy
            </a>{" "}
            &middot;{" "}
            <a className="link-color middle" href="/">
              Terms
            </a>
          </div>
          <div className="float-right">
            made by{" "}
            <a
              className="link-color middle"
              target="_blanck"
              href="https://www.anyonecancode.in/"
            >
              anyonecancode.in
            </a>
          </div>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
          />
        </footer>
      </div>
    );
  }
}

export default Footer;
