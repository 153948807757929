import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./home";
import Product from "./product";
// import ColdRoll from "./pages/coldroll";
import HotRoll from "./pages/sheets";
// import Galvanised from "./pages/galvanised";
// import Access from "./pages/access"
import Cement from "./pages/cement";
// import PrePaint from "./pages/prepainted";
import Steel from "./pages/steel";
import Others from "./pages/hardware";
import TilesS from "./pages/tiles-S";
// import Pipes from "./pages/pipes";
// import Polycarbonates from "./pages/poly";
import ScrollToTop from "./scrolltotop";

const NavBarTop = () => {
  return (
    <Router>
      <ScrollToTop>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/product" component={Product}>
          {/* <Product /> */}
        </Route>
        <Route path="/sheets"  component={HotRoll}>
          {/* <HotRoll /> */}
        </Route>
        {/* <Route path="/hotroll">
          <HotRoll />
        </Route>
        <Route path="/galvanised">
          <Galvanised />
        </Route> */}
        {/* <Route path="/access">
          <Access />
        </Route> */}
        <Route path="/cement" component={Cement}>
          {/* <Cement /> */}
        </Route>
        {/* <Route path="/prepainted">
          <PrePaint />
        </Route> */}
        <Route path="/steel" component={Steel}>
          {/* <Steel /> */}
        </Route>
        <Route path="/hardware" component={Others}>
          {/* <Others /> */}
        </Route>
        <Route path="/tiles-S" component={TilesS}>
          {/* <TilesS /> */}
        </Route>
        {/* <Route path="/pipes">
          <Pipes />
        </Route>
        <Route path="/poly">
          <Polycarbonates />
        </Route> */}
      </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default NavBarTop;
