import React from "react";
import HouseImg from "./assets/img/ss-shop1.webp";

const About = () => {
  return (
    <div className="container" id="about">
      <div className="row featurette">
        <div className="col-md-6">
          <h2 className="featurette-heading glow">
            About. <span className="text-muted"> Surabhi Steel.</span>
          </h2>
          <p className="lead" >
            Surabhi Steel House is a 25 years old organisation with 125 years of experience in supplying all materials required for consutruction of any budget without compromising quality. We are one of the leading stockist with multiple warehouses and showrooms.Construction Engineers trust us for the Complete Consutrction Solution. Started in 1993-94 with a small sized business of selling steel sheets we have grown to a House of Counstruction materials in Northern Part of Karnataka as Surabhi Steel House. We support our costomers with pickup and drop services at their door step. We also are trend setters in respect to creating new warehouse and showroom facilities. We have grown in business mainly because we have valued relations by retaining customers for quality, suppliers for sales & employees for commitment for three decades.
          </p>
        </div>
        <div className="col-md-6" style={{marginTop:"5rem"}}>
          <svg
            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
            width="500"
            height="500"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid slice"
            focusable="false"
            role="img"
            aria-label="Placeholder: 500x500"
          >
            <title>Placeholder</title>

            <image href={HouseImg} alt="logo-img" width="100%" height="100%"/>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default About;
