import React from 'react';
import Image from 'react-bootstrap/Image'
import Jsw from './assets/img/jsw-logo.webp';
import CementB from './assets/img/ultratechcement.webp';
import KamadhenuB from './assets/img/kamadhenu.webp';
import TataB from './assets/img/tata-tiscon-b.webp';
import Cera from './assets/img/cera.webp';
import Polo from './assets/img/polo_01.webp';
import Essar from './assets/img/essar-logo.webp';
import Apolo from './assets/img/apolo-b.webp';
import TataS from './assets/img/tatalogo-b.webp';


function Brand() {

    return (
        <div className="container">
         <hr className="featurette-divider" />
        <h1 className="prod-heading" ><b>DEALERS IN</b></h1>
        
            <div className="row">
                <div className="cardcolumn ">
                    <Image fluid src={CementB} alt="brand image" style={{width:"auto" ,height:"90px"}}  />
                </div>  
                <div className="cardcolumn ">
                    <Image fluid src={TataB} alt="brand image" style={{width:"auto" ,height:"90px"}} />
                </div>           
                <div className="cardcolumn ">
                    <Image fluid src={KamadhenuB} alt="brand image" style={{width:"auto" ,height:"90px"}}  />
                </div>
                <div className="cardcolumn ">
                    <Image fluid src={Jsw} alt="brand image" style={{width:"auto" ,height:"90px"}}  />
                </div>
                <div className="cardcolumn ">
                    <Image fluid src={Cera} alt="brand image" style={{width:"auto" ,height:"90px"}}  />
                </div>
                <div className="cardcolumn ">
                    <Image fluid src={Essar} alt="brand image" style={{width:"auto" ,height:"90px"}}  />
                </div>
                <div className="cardcolumn ">
                    <Image fluid src={Apolo} alt="brand image" style={{width:"auto" ,height:"90px"}}  />
                </div>
                <div className="cardcolumn ">
                    <Image fluid src={TataS} alt="brand image" style={{width:"auto" ,height:"90px"}}  />
                </div>
                <div className="cardcolumn ">
                    <Image fluid src={Polo } alt="brand image" style={{width:"auto" ,height:"90px"}}  />
                </div>
            
            </div>
            
    
        </div>
    )

}

export default Brand;