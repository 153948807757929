import React from 'react';
import './assets/css/App.css';
import {Image} from 'react-bootstrap';
import { CurrentSlide, PlusSlides } from './showslide';
import RoofSheet from './assets/img/roofing.webp';
import Tiles from './assets/img/tiles.webp';
import TmtSteel from './assets/img/tmt-steel.webp';
import HrSheet from './assets/img/hr-sheet-01.webp';
import CementPole from './assets/img/cement-pole.webp';
import SquareBar from './assets/img/squarebar.webp' 


class Slider extends React.Component {
  render () {
    return (
      <div className="App container" >
        <div className="heading">
           <h2>Featured Product</h2>
        </div>
          <div className="mySlides" style={{display:'block'}}>
            <div className="numbertext">1 / 6</div>
             <Image fluid src={RoofSheet} style= {{ width:"auto", height:"400px" }} alt="" />
          </div>

          <div className="mySlides">
            <div className="numbertext">2 / 6</div>
             <Image fluid src={Tiles} style= {{ width:"auto", height:"400px" }} alt="" />
          </div>

          <div className="mySlides">
            <div className="numbertext">3 / 6</div>
             <Image fluid src={TmtSteel} style= {{ width:"auto", height:"400px" }} alt="" />
          </div>
            
          <div className="mySlides">
            <div className="numbertext">4 / 6</div>
             <Image fluid src={HrSheet} style= {{ width:"auto", height:"400px" }} alt="" />
          </div>

          <div className="mySlides">
            <div className="numbertext">5 / 6</div>
             <Image fluid src={CementPole} style= {{ width:"auto", height:"400px" }} alt="" />
          </div>
            
          <div className="mySlides">
            <div className="numbertext">6 / 6</div>
             <Image fluid src={SquareBar} style= {{ width:"auto", height:"400px" }} alt="" />
          </div>
            
          <span className="prev" onClick={PlusSlides} src="none" value = "-1">❮</span>
          <span className="next" onClick={PlusSlides} src="none" value = "1">❯</span>

          <div className="caption-container">
            <p id="caption" style={{color:'white'}}></p>
          </div>
         
          <div id="myDIV" className="d-none d-lg-block">
              <div className="row">
                  <div className="col-lg-2">
                     <Image className="demo cursor" src={RoofSheet} onClick={CurrentSlide} value="1" style= {{ width:"100%" }} alt="Roof Sheet" />
                  </div>
                  <div className="col-lg-2">
                     <Image className="demo cursor" src={Tiles} onClick={CurrentSlide} value="2" style= {{ width:"100%" }} alt="Vertified Tiles" />
                  </div>
                  <div className="col-lg-2">
                     <Image className="demo cursor" src={TmtSteel} onClick={CurrentSlide} value="3" style= {{ width:"100%" }} alt="TMT Steel" />
                  </div>
                  <div className="col-lg-2">
                     <Image className="demo cursor" src={HrSheet} onClick={CurrentSlide} value="4" style= {{ width:"100%" }} alt="HR Sheet" />
                  </div>
                  <div className="col-lg-2">
                     <Image className="demo cursor" src={CementPole} onClick={CurrentSlide} value="5" style= {{ width:"100%" }} alt="Cement Pole" />
                  </div>
                  <div className="col-lg-2">
                     <Image className="demo cursor" src={SquareBar}  onClick={CurrentSlide} value="6" style= {{ width:"100%" }} alt="Square Bars" />
                  </div>
              </div>
          </div>
      </div>
    );
  }
}

export default Slider;