                                // Hot Roll Products

export const categoryhr = [
                          {title : "HR Plain Plate" , url : "./assets/img/hr-sheet.webp" , id : '#hr-plain'},
                          {title : "HR Chekkered Plate" , url : "./assets/img/Hr-chakkared-plate-2-1.webp" , id : '#hr-check'},
                          {title : "CR Sheet", url : "./assets/img/crsheet/one.webp",id:'#cr-sheet'},
                          {title : "TATA GC Sheets" , url : "./assets/img/tata-gc/1-3.webp",id:'#t-gc-sheet'},
                          {title : "TATA GP Sheets" , url : "./assets/img/tata-gp/3-3.webp",id:'#t-gp-sheet'},
                          {title : "JSW GC Sheets" , url : "./assets/img/jsw-gc.webp",id:'#j-gc-sheet'},
                          {title : "JSW GP Sheets" , url : "./assets/img/tata-gp/3-3.webp",id:'#j-gp-sheet'},
                          {title : "Colour Coated Sheet" , url : "./assets/img/color_coated_sheet.webp",id:'#cc-sheet'},
                          {title : "Galvalume Profile" , url : "./assets/img/galvanum.webp",id:'#galvanum-p'},
                          {title : "Plain Ridge" , url : "./assets/img/plane-ridge.webp",id:'#plain-r'},
                          {title : "North Curve" , url : "./assets/img/NORTH-CURVE-1.webp",id:'#north-c'},
                          {title : "Plain Curve" , url : "./assets/img/Plain-curve.webp",id:'#plain-c'},
                          {title : "Z - Curve" , url : "./assets/img/Z-curve.webp",id:'#z-curve'},
                          {title : "Side Curve" , url : "./assets/img/hotroll1.webp",id:'#side-c'},
                          {title : "Front Bend" , url : "./assets/img/FRONT-BEND-1.webp",id:'#front-b'},
                          {title : "Full Bend" , url : "./assets/img/FULL-BEND.webp",id:'#full-b'},
                          {title : "Turbo Ventilator Fan" , url : "./assets/img/turbo.webp",id:'#turbo-v'},
                          {title : "Nails" , url : "./assets/img/nails-01.webp",id:'#nails'},
                          {title : "Plain" , url : "./assets/img/polycorb/one.webp",id:'#plain-p'},
                          {title : "Corogated" , url : "./assets/img/polycorb/two.webp",id:'#corogated-p'},
                          {title : "Vedganga Water Tanks" , url : "./assets/img/water-tanks/1-3.webp",id:'#water-t-v'},
                          {title : "Supreme Water Tanks" , url : "./assets/img/water-tanks/2-3.webp",id:'#water-t-su'},
                          {title : "Sintex Water Tanks" , url : "./assets/img/water-tanks/3-3.webp",id:'#water-t-si'},
                          
                          ];

                                //   cold roll products

// export const categorycr = [
//                             {title : "CR Sheet", url : "./assets/img/crsheet/one.webp",id:'#cr-sheet'},
//                             ];

                                //   Galvanised products

// export const categorygalvan = [
//                                 {title : "TATA GC Sheets" , url : "./assets/img/tata-gc/1-3.webp",id:'#t-gc-sheet'},
//                                 {title : "TATA GP Sheets" , url : "./assets/img/tata-gp/3-3.webp",id:'#t-gp-sheet'},
//                                 {title : "JSW GC Sheets" , url : "./assets/img/jsw-gc.webp",id:'#j-gc-sheet'},
//                                 {title : "JSW GP Sheets" , url : "./assets/img/tata-gp/3-3.webp",id:'#j-gp-sheet'}
                                
//                               ];

                                // pre painted products

// export const categorypre = [
//                             {title : "960 Profile" , url : "./assets/img/960-profile.webp",id:'#960p'},
//                             {title : "TATA Profile 1070" , url : "./assets/img/tata.webp",id:'#1070p'},
//                             {title : "TATA Profile 1270" , url : "./assets/img/tata.webp",id:'#1270p'},
//                             {title : "Colour Coated Steel Sheet" , url : "./assets/img/color_coated_sheet.webp",id:'#cc-sheet'},
//                             {title : "Galvalum Profile" , url : "./assets/img/galvanum.webp",id:'#galvanum-p'},
//                             {title : "Essar Profile" , url : "./assets/img/essar.webp",id:'#essar-p'},
//                             {title : "JSW Profile" , url : "./assets/img/jsw.webp",id:'#jsw-p'},
//                           ];

                                //   Accessories products

// export const categoryaccess = [
//                                 {title : "Plain Ridge" , url : "./assets/img/plane-ridge.webp",id:'#plain-r'},
//                                 {title : "North Curve" , url : "./assets/img/NORTH-CURVE-1.webp",id:'#north-c'},
//                                 {title : "Plain Curve" , url : "./assets/img/Plain-curve.webp",id:'#plain-c'},
//                                 {title : "Z - Curve" , url : "./assets/img/Z-curve.webp",id:'#z-curve'},
//                                 {title : "Side Curve" , url : "./assets/img/hotroll1.webp",id:'#side-c'},
//                                 {title : "Front Bend" , url : "./assets/img/FRONT-BEND-1.webp",id:'#front-b'},
//                                 {title : "Full Bend" , url : "./assets/img/FULL-BEND.webp",id:'#full-b'},
//                                 {title : "Turbo Ventilator Fan" , url : "./assets/img/turbo.webp",id:'#turbo-v'},
//                                 {title : "Nails" , url : "./assets/img/nails-01.webp",id:'#nails'}
//                               ];

                                // cement products 

export const categorycement = [
                                {title : "Cement" , url : "./assets/img/cement.webp",id:'#cement'},
                                {title : "Cement pole" , url : "./assets/img/cpol.webp",id:'#cement-p'},
                                {title : "Door With Frame" , url : "./assets/img/cdoor-1.webp",id:'#door'},
                                {title : "Frame" , url : "./assets/img/cdoor-2.webp",id:'#frame'},
                                {title : "Windows" , url : "./assets/img/cwindow.webp",id:'#window'},
                                {title : "AAC Block" , url : "./assets/img/aac_block.webp",id:'#aac-b'},
                                {title : "Cement Sheet" , url : "./assets/img/char-logo.webp",id:'#cement-s'}
                              ];

                                // Steel products 

export const categorysteel = [
                                {title : "Kamadhenu TMT Bars" , url : "./assets/img/kamadhenu.webp",id:'#k-tmt'},
                                {title : "TATA Tiscon TMT Bars" , url : "./assets/img/tata-tescon.webp",id:'#t-tmt'},
                                {title : "Kay2 TMT Bars" , url : "./assets/img/kay2.webp",id:'#k2-tmt'},
                                {title : "Angles" , url : "./assets/img/angles.webp",id:'#angle'},
                                {title : "Rectangles" , url : "./assets/img/rectangle.webp",id:'#rectangle'},
                                {title : "Flats" , url : "./assets/img/flats.webp",id:'#flat'},
                                {title : "Channels" , url : "./assets/img/channels.webp",id:'#channel'},
                                {title : "High Beams" , url : "./assets/img/high-beam.webp",id:'#high-b'},
                                {title : "Square Bars" , url : "./assets/img/squarebar.webp",id:'#square-b'},
                                {title : "Round Bars" , url : "./assets/img/roundbar.webp",id:'#round-b'},
                                {title : "Jindal TMT Steel" , url : "./assets/img/jindal/jindal_02.webp",id:'#j-tmt'},
                                {title : "Bright Bars" , url : "./assets/img/bright-bars/bright-bar01.webp",id:"bright-b"},
                                // {title : "Steel Hollow Section" , url : "./assets/img/tata_structura.webp"}
                            ];
                            
                            // Tiles & Saniteries products 

export const categorytiles = [
                                {title : "Kajaria Ceramics" , url : "./assets/img/kajaria_tiles.webp",id:'#k-ceramic'},
                                {title : "Simpolo Vitrified Tiles" , url : "./assets/img/simpolo_tiles.webp",id:'#s-v-tiles'},
                                {title : "Wall Tiles" , url : "./assets/img/wall_tiles.webp",id:'#wall-t'},
                                {title : "Floor and Parking Tiles" , url : "./assets/img/floor_tiles.webp",id:'#f-p-tiles'},
                                {title : "Porcelain Tiles" , url : "./assets/img/porcelain_tiles.webp",id:'#porcelain-t'},
                                {title : "Double Charge Tiles" , url : "./assets/img/double_charge_tiles.webp",id:'#d-c-tiles'},
                                {title : "GVT Tiles" , url : "./assets/img/gvt_tiles.webp",id:'#gvt-tiles'},
                                {title : "PGVT" , url : "./assets/img/pgvt_tiles.webp",id:'#pgvt-tiles'},
                                {title : "CERA Sanityware" , url : "./assets/img/cera_sanityware.webp",id:'#cera-s'}
                            ];

                                        //   polycarbonate products

// export const polycorbonate = 
//                             [
//                                 {title : "Plain" , url : "./assets/img/polycorb/one.webp",id:'#plain-p'},
//                                 {title : "Corogated" , url : "./assets/img/polycorb/two.webp",id:'#corogated-p'},
//                             ];

                                        // pipes

// export const pipes = 
//                             [
//                                 {title : "Garden Pipe" , url : "./assets/img/garden_pipe.webp"},
//                                 {title : "Finolex" , url : "./assets/img/finolex.webp"},
//                                 {title : "Power flex " , url : "./assets/img/powerflex.webp"},
//                                 {title : "Type 4" , url : "./assets/img/garden_pipe.webp"},
//                                 {title : "Type 5" , url : "./assets/img/finolex.webp"}
//                             ];

                                // other Products 

export const categoryother = [
                                {title : "Chain Link Mesh" , url : "./assets/img/chainlink.webp",id:'#chain-l-m'},
                                {title : "Barbed Wire" , url : "./assets/img/barbed.webp",id:'#barbed-w'},
                                {title : "GI Wire" , url : "./assets/img/gi-wire/1-3.webp",id:'#gi-wire'},
                                {title : "Binding Wire(ms)" , url : "./assets/img/ms-binding-wire.webp",id:'#binding-wire'},
                                {title : "Nut-Bolts" , url : "./assets/img/nut-bolts.webp",id:'#nut-bolt'},
                                {title : "Cut of Wheel" , url : "./assets/img/cutwheel.webp",id:'#cut-wheel'},
                                {title : "Handrop Set" , url : "./assets/img/handrop.webp",id:'#handrop'},
                                {title : "Hexa Blade" , url : "./assets/img/hexablade/2-3.webp",id:'#hexa-b'},
                                {title : "Hinges" , url : "./assets/img/hing.webp",id:'#hinge'},
                                {title : "Hooks" , url : "./assets/img/hooks/hooks-v-2.webp",id:'#hook'},
                                {title : "Straight Rod" , url : "./assets/img/srod.webp",id:'#straight-r'},
                                {title : "Washer GI" , url : "./assets/img/washer.webp",id:'#washer'},
                                {title : "Weldmesh Jali" , url : "./assets/img/weldmesh.webp",id:'#weldmesh-j'},
                                {title : "Roofing Nails" , url : "./assets/img/roofnail.webp",id:'#roof-nails'},
                                {title : "Rivet" , url : "./assets/img/rivet/1-3.webp",id:'#rivet'},
                                {title : "Nails" , url : "../assets/img/nails-v-2.webp",id:'#nails'}
                            ];

                                // SubProducts Data After the Multi Carousel

export const hrproducts = 
              [
                {name : 'HR Plain Plate' , image1 : './assets/img/hrsheet/1-3.webp', image2 :'./assets/img/hrsheet/2-3.webp' , image3 : './assets/img/hrsheet/3-3.webp' , id : "hr-plain" , thickness : "1.6mm to 25mm" , width:'3ft, 4ft & 5ft'},
                {name : 'HR Chequered Plate' , image1 : './assets/img/checker/1-3.webp' , image2 : './assets/img/checker/2-3.webp' , image3 : './assets/img/checker/3-3.webp' ,thickness : "1.6mm to 25mm" , width:'3ft, 4ft & 5ft', id : "hr-check"}
                
             ];

             
export const crproducts = 
              [
                {name : 'CR Sheet' , image1 : './assets/img/cr_sheet_01.webp', image2 :'./assets/img/cr_sheet_01.webp' , image3 : './assets/img/cr_sheet_01.webp' , thickness : "0.8mm to 2mm" , width:'3ft to 4ft', id : "cr-sheet"}
                
              ];

export const galvanproducts = 
              [
                {name : 'TATA GC Sheets' , image1 : './assets/img/tata-gc/1-3.webp', image2 :'./assets/img/tata-gc/2-3.webp' , image3 : './assets/img/tata-gc/3-3.webp' , id : "t-gc-sheet" , thickness : "0.16 to 0.60mm", width : "3ft to 4ft" , length : "8ft to 20ft" },
                {name : 'TATA GP Sheets' , image1 : './assets/img/tata-gp/3-3.webp' , image2 : './assets/img/tata-gp/2-3.webp' , image3 : './assets/img/tata-gp/1-3.webp' , id : "t-gp-sheet" , thickness : "0.35mm to 2mm", width : "3ft to 4ft" , length : "8ft"},
                {name : 'JSW GP Sheets' , image1 : './assets/img/tata-gp/2-3.webp' , image2 : './assets/img/tata-gp/1-3.webp' , image3 : './assets/img/tata-gp/3-3.webp' , id : "j-gp-sheet" , thickness : "0.35mm to 2mm", width : "3ft to 4ft" , length : "8ft"},
                {name : 'JSW GC Sheets' , image1 : './assets/img/jsw-gc/1-3.webp' , image2 : './assets/img/jsw-gc/2-3.webp' , image3 : './assets/img/jsw-gc/1-3.webp' ,id : "j-gc-sheet" , thickness : "0.16 to 0.60mm", width : "3ft to 4ft" , length : "8ft to 20ft"}
             ];

export const preproducts = 
              [
                
                {name : 'Colour Coated Sheet' , image1 : './assets/img/color-coated01.jpeg' , image2 : './assets/img/color_coated_sheet.webp' , image3 : './assets/img/color-coated01.jpeg' , width:"3.5ft", length:"8 to 25ft",color:'RAL 5012 blue | RAL 9002 white | RAL 6011 green mist | RAL 2008 orange | RAL 5018 relience green | brick Red. | Royal blue | camouflage Green | Wood finish | Camouflage Blue | Camouflage Red extras as per RAL code chart.', id : "cc-sheet"},
                {name : 'Galvalume Profile' , image1 : './assets/img/galvanume/1-3.webp' , image2 : './assets/img/galvanume/2-3.webp' , image3 : './assets/img/galvanume/1-3.webp',width:"3.5ft", length:"8 to 25ft",color:'Silver', id : "galvanum-p"},   
                
                
             ];


export const accessproducts = 
              [
                {name : 'Turbo Ventilator Fan' , image1 : './assets/img/turbofan/1-3.webp' , image2 : './assets/img/turbofan/2-3.webp' , image3 : './assets/img/turbofan/3-3.webp', id : "turbo-v"},
                {name : 'Vedganga Water Tank' , image1 : './assets/img/water-tanks/1-3.webp' , image2 : './assets/img/water-tanks/1-3.webp' , image3 : './assets/img/water-tanks/1-3.webp',size:"3000 ltr, 2000 ltr, 1000 ltr, 750 ltr, 500 ltr, 300 ltr", id : "water-t-v"},
                {name : 'Supreme Water Tank' , image1 : './assets/img/water-tanks/2-3.webp' , image2 : './assets/img/water-tanks/2-3.webp' , image3 : './assets/img/water-tanks/2-3.webp',size:'1000 ltr, 500 ltr', id : "water-t-su"},
                {name : 'Sintex Water Tank' , image1 : './assets/img/water-tanks/3-3.webp' , image2 : './assets/img/water-tanks/3-3.webp' , image3 : './assets/img/water-tanks/3-3.webp',size:'1000 ltr', id : "water-t-si"},
                {name : 'Plain Ridge' , image1 : './assets/img/planeridge/3-3.webp', image2 :'./assets/img/planeridge/2-3.webp' , image3 : './assets/img/planeridge/1-3.webp' , id : "plain-r"},
                {name : 'North Curve' , image1 : './assets/img/northcurves/1-3.webp' , image2 : './assets/img/northcurves/2-3.webp' , image3 : './assets/img/northcurves/3-3.webp' , id : "north-c"},
                {name : 'Plain Curve' , image1 : './assets/img/plaincurve/1-3.webp' , image2 : './assets/img/plaincurve/1-3.webp' , image3 : './assets/img/plaincurve/1-3.webp' , id : "plain-c"},
                {name : 'Z - Curve' , image1 : './assets/img/z-curve/2-3.webp' , image2 : './assets/img/z-curve/1-3.webp' , image3 : './assets/img/z-curve/1-3.webp' ,id : "z-curve"},
                {name : 'Side Curve' , image1 : './assets/img/sidecurves/1-3.webp' , image2 : './assets/img/sidecurves/2-3.webp' , image3 : './assets/img/sidecurves/1-3.webp', id : "side-c"},
                {name : 'Front Bend' , image1 : './assets/img/frontbend/1-3.webp' , image2 : './assets/img/frontbend/2-3.webp' , image3 : './assets/img/frontbend/1-3.webp', id : "front-b"},
                {name : 'Full Bend' , image1 : './assets/img/fullbend/1-3.webp' , image2 : './assets/img/fullbend/3-3.webp' , image3 : './assets/img/fullbend/2-3.webp', id : "full-b"},
                {name : 'Screws' , image1 : './assets/img/screws/1-3.webp' , image2 : './assets/img/screws/2-3.webp' , image3 : './assets/img/screws/3-3.webp', size:"20mm to 65mm",id : "#"}
              ]             

export const cementproducts = 
              [
                {name : 'Cement' , image1 : './assets/img/ultratechcement.webp' , image2: './assets/img/cement/1-3.webp ',image3:'./assets/img/cement.webp', id : "cement" },
                {name : 'Windows' , image1 : './assets/img/cwindow/1-3.webp' , image2 : './assets/img/cwindow/2-3.webp' , image3 : './assets/img/cwindow/3-3.webp' ,size : '1.5X1.5ft, 2X2ft, 2.5X2.5ft, 2X3ft, 2.5X3ft, 3x3ft',id : "window"},
                {name : 'Door With Frame' , image1 : './assets/img/cdoor-2.webp' , image2 : './assets/img/cdoor-1/1-3.webp' , image3 : './assets/img/cdoor-2.webp' , size:"6X2ft, 5.5X2ft, 6X2.5ft, 5.5X2.5ft, 7X3ft",id : "door"},
                {name : 'Frame' , image1 : './assets/img/cdoor-1/2-3.webp' , image2 : './assets/img/cdoor-1/3-3.webp' , image3 : './assets/img/cdoor-1/2-3.webp' , size:"6X2ft, 5.5X2ft, 6X2.5ft, 5.5X2.5ft, 7X3ft",id : "frame"},
                {name : 'AAC Block' , image1 : './assets/img/aac_block_01.webp' , image2 : './assets/img/aac_block_02.webp' , image3 : './assets/img/aac_block_03.webp' ,size:'8X24X4inch, 8X24X6inch,  8X24X8inch, 8X24X9inch, 8X24X12inch',id : "aac-b"},
                {name : 'Cement Pole' , image1 : './assets/img/cement-pole/1-3.webp' , image2 : './assets/img/cement-pole/2-3.webp' , image3 : './assets/img/cement-pole/3-3.webp' ,length:'6ft to 12ft',id : "cement-p"},
                {name : 'Cement Sheet' , image1 : './assets/img/cement-sheet/2-3.webp' , image2 : './assets/img/cement-sheet/1-3.webp' , image3 : './assets/img/cement-sheet/2-3.webp' ,length:'2m to 3.6m',width:'1m',id : "cement-s"}
              
              ];

export const steelproducts = 
              [
                {name:'Kamadhenu TMT Bars', image1:'./assets/img/kamadhenu/1-3.webp', image2 :'./assets/img/kamadhenu/2-3.webp' , image3 : './assets/img/kamadhenu/3-3.webp' , thickness:"6mm to 32mm",length:'12m', id : "k-tmt"},
                {name : 'TATA Tiscon TMT Bars' , image1 : './assets/img/tata-tescon/1-3.webp' , image2 : './assets/img/tata-tescon/2-3.webp' , image3 : './assets/img/tata-tescon/3-3.webp', thickness:"6mm to 32mm",length:'12m',id : "t-tmt"},
                {name : 'Kay2 TMT Bars' , image1 : './assets/img/kay2/1-3.webp' , image2 : './assets/img/kay2/2-3.webp' , image3 : './assets/img/kay2/3-3.webp' , thickness:"6mm to 32mm",length:'12m',id : "k2-tmt"},
                {name : 'Jindal TMT Bars' , image1 : './assets/img/jindal/jindal_01.webp' , image2 : './assets/img/jindal/jindal_02.webp' , image3 : './assets/img/jindal/jindal_04.webp' , thickness:"6mm to 32mm",length:'12m',id : "j-tmt"},
                {name : 'MS Angles' , image1 : './assets/img/angles/1-3.webp' , image2 : './assets/img/angles/2-3.webp' , image3 : './assets/img/angles/3-3.webp' , thickness:"3mm to 6mm",size:"20X20mm to 75X75mm", id : "angle"},
                {name : 'MS Flats' , image1 : './assets/img/flats/1-3.webp' , image2 : './assets/img/flats/2-3.webp' , image3 : './assets/img/flats/3-3.webp' , thickness:"3mm to 16mm",size:"25mm to 200mm",id : "flat"},
                {name : 'MS Channels' , image1 : './assets/img/channels/1-3.webp' , image2 : './assets/img/channels/2-3.webp' , image3 : './assets/img/channels/3-3.webp' ,thickness:"3mm to 6mm",size:"70mm to 200mm",id : "channel"},
                {name : 'High Beams' , image1 : './assets/img/high-beam/1-3.webp' , image2 : './assets/img/high-beam/2-3.webp' , image3 : './assets/img/high-beam/3-3.webp', size:"100mm to 250mm", id : "high-b"},
                {name : 'Square Bars' , image1 : './assets/img/squarebar/1-3.webp' , image2 : './assets/img/squarebar/2-3.webp' , image3 : './assets/img/squarebar/3-3.webp',size:"8mm to 25mm", id : "square-b"},
                {name : 'Round Bars' , image1 : './assets/img/roundbars/1-3.webp' , image2 : './assets/img/roundbars/3-3.webp' , image3 : './assets/img/roundbars/2-3.webp', size:"6mm to 65mm", id : "round-b"},
                {name : 'Bright Bars' , image1 : './assets/img/bright-bars/bright-bar01.webp' , image2 : './assets/img/bright-bars/bright-bar02.webp' , image3 : './assets/img/bright-bars/bright-bar03.webp', size:"6mm to 65mm", id : "bright-b"},
                {name : 'Round Tubes' , image1 : './assets/img/rtubes/1-3.webp' , image2 : './assets/img/rtubes/3-3.webp' , image3 : './assets/img/rtubes/2-3.webp', thickness:"0.80mm to 4.00mm", size:"12mm to 300mm", id : "round-t"},
                {name : 'Square Tubes' , image1 : './assets/img/stubes/1-3.webp' , image2 : './assets/img/stubes/3-3.webp' , image3 : './assets/img/stubes/2-3.webp', thickness:"0.80mm to 4.00mm", size:"20mm to 250mm",id : "square-t"},
                {name : 'Rectangles' , image1 : './assets/img/rectangle.webp' , image2 : '../assets/img/rectangle.webp' , image3 : './assets/img/rectangle.webp',id : "rect-a"}
             ];

export const polycorbproducts = 
              [
                {name:'Plain Sheets', image1:'./assets/img/polycorb/one.webp', image2 :'./assets/img/polycorb/one.webp' , image3 : './assets/img/polycorb/one.webp' , width:"4ft, 7ft",length:'39ft', id : "plain-p"},
                {name : 'Corogated Sheets' , image1 : './assets/img/corogated/2-3.webp' , image2 : './assets/img/corogated/1-3.webp' , image3 : './assets/img/corogated/2-3.webp',width:"3.5ft",length:'8 to 16ft', id : "corogated-p"},
                
              ];

            

export const tilesproducts = 
              [
                {name:'Kajaria Ceramics', image1:'./assets/img/kajaria_01.webp', image2 :'../assets/img/kajaria_02.webp' , image3 : './assets/img/kajaria_03.webp' , width:"", id : "k-ceramic"},
                {name : 'Simpolo Vitrified Tiles' , image1 : '../assets/img/simpolo_tiles.webp' , image2 : './assets/img/simpolo_tiles.webp' , image3 : './assets/img/simpolo_tiles.webp', width:"",id : "s-v-tiles"},
                {name : 'Wall Tiles' , image1 : './assets/img/wall_tiles.webp' , image2 : './assets/img/wall_tiles.webp' , image3 : './assets/img/wall_tiles.webp' , width:"wall-t",id : "hr-plate"},
                {name : 'Floor and Parking Tiles' , image1 : './assets/img/floor_tiles.webp' , image2 : '../assets/img/floor_tiles.webp' , image3 : './assets/img/floor_tiles.webp' , thickness:"",size:"", id : "f-p-tiles"},
                {name : 'Porcelain Tiles' , image1 : './assets/img/porcelain_tiles.webp' , image2 : './assets/img/porcelain_tiles.webp' , image3 : './assets/img/porcelain_tiles.webp' , thickness:"",size:"",id : "porcelain-t"},
                {name : 'Double Charge Tiles' , image1 : './assets/img/double_charge_tiles.webp' , image2 : '../assets/img/double_charge_tiles.webp' , image3 : './assets/img/double_charge_tiles.webp' , thickness:"",size:"",id : "d-c-tiles"},
                {name : 'GVT Tiles' , image1 : './assets/img/gvt_tiles.webp' , image2 : './assets/img/gvt_tiles.webp' , image3 : './assets/img/gvt_tiles.webp' , thickness:"",size:"",id : "gvt-tiles"},
                {name : 'PGVT' , image1 : './assets/img/pgvt_tiles.webp' , image2 : './assets/img/pgvt_tiles.webp' , image3 : './assets/img/pgvt_tiles.webp' , thickness:"",size:"",id : "pgvt-tiles"},
                {name : 'CERA Sanityware' , image1 : './assets/img/cera_sanityware_01.webp' , image2 : './assets/img/cera_sanityware_02.webp' , image3 : './assets/img/cera_sanityware_03.webp' , thickness:"",size:"",id : "cera-s"}
             ];

// export const pipeproducts = 
//              [
//                {name : 'Garden pipe' , image1 : './assets/img/garden_01.webp' , image2: './assets/img/garden_01.webp',image3:'./assets/img/garden_01.webp', id : "hr-coil" },
//                {name : 'Finolex' , image1 : './assets/img/finolex_01.webp' , image2 : './assets/img/finolex_01.webp' , image3 : './assets/img/finolex_01.webp' ,id : "hr-cc",size :"50/50"},
//                {name : "Power Flex" , image1 : './assets/img/powerflex_01.webp' , image2 : './assets/img/powerflex_01.webp' , image3 : './assets/img/powerflex_01.webp' ,id : "hr-plate", size :"50/50"},
//              ];

export const hardwareproducts = 
             [
                {name : 'Chain Link Mesh' , image1 : './assets/img/chainlink/1-3.webp', image2 :'./assets/img/chainlink/2-3.webp' , image3 : './assets/img/chainlink/3-3.webp',style:"Customised Sizes Available" , id : "chain-l-m"},
                {name : 'Barbed Wire' , image1 : './assets/img/barbedwire/2-3.webp' , image2 : './assets/img/barbedwire/1-3.webp' , image3 : './assets/img/barbedwire/3-3.webp' , id : "barbed-wire"},
                {name : 'GI Wire' , image1 : './assets/img/gi-wire/1-3.webp' , image2 : './assets/img/gi-wire/2-3.webp' , image3 : './assets/img/gi-wire/3-3.webp' , id : "gi-wire"},
                {name : 'Binding Wire(ms)' , image1 : './assets/img/msbinding-wire/1-3.webp' , image2 : './assets/img/msbinding-wire/2-3.webp' , image3 : './assets/img/msbinding-wire/3-3.webp' ,id : "binding-wire"},
                {name : 'Nut & Bolts' , image1 : './assets/img/nut-bolts/1-3.webp' , image2 : './assets/img/nut-bolts/2-3.webp' , image3 : './assets/img/nut-bolts/3-3.webp', id : "nut-bolt"},
                {name : 'Cut of Wheel' , image1 : './assets/img/cutwheel/1-3.webp', image2 :'./assets/img/cutwheel/2-3.webp' , image3 : './assets/img/cutwheel/3-3.webp' , id : "cut-wheel"},
                {name : 'Handrop Set' , image1 : './assets/img/handrop/1-3.webp' , image2 : './assets/img/handrop/2-3.webp' , image3 : './assets/img/handrop/3-3.webp' , id : "handrop"},
                {name : 'Hexa Blade' , image1 : './assets/img/hexablade/1-3.webp' , image2 : './assets/img/hexablade/2-3.webp' , image3 : './assets/img/hexablade/3-3.webp' ,id : "hexa-b"},
                {name : 'Hinges' , image1 : './assets/img/hinge/1-3.webp' , image2 : './assets/img/hinge/3-3.webp' , image3 : './assets/img/hinge/3-3.webp', id : "hinge"},
                {name : 'Hooks' , image1 : './assets/img/hooks/hooks-v-2.webp', image2 :'./assets/img/hooks/hooks-v-2.webp' , image3 : './assets/img/hooks/hooks-v-2.webp' , id : "hook"},
                {name : 'Straight Rod ' , image1 : './assets/img/srod/1-3.webp' , image2 : './assets/img/srod/2-3.webp' , image3 : './assets/img/srod/3-3.webp' , id : "straight-r"},
                {name : 'Washer GI ' , image1 : './assets/img/washer/1-3.webp' , image2 : './assets/img/washer/2-3.webp' , image3 : './assets/img/washer/3-3.webp' , id : "washer"},
                {name : 'Weldmesh Jali' , image1 : './assets/img/weldmesh/1-3.webp' , image2 : './assets/img/weldmesh/3-3.webp' , image3 : './assets/img/weldmesh/2-3.webp' ,id : "weldmesh-j"},
                {name : 'Roofing Nails' , image1 : './assets/img/roofnail/1-3.webp' , image2 : './assets/img/roofnail/2-3.webp' , image3 : './assets/img/roofnail/3-3.webp', id : "roof-nail"},
                {name : 'Rivet' , image1 : './assets/img/rivet/1-3.webp' , image2 : './assets/img/rivet/2-3.webp' , image3 : './assets/img/rivet/3-3.webp', id : "rivet"},
                {name : 'Nails' , image1 : './assets/img/nails-v-2.webp' , image2 : './assets/img/nails-v-2.webp' , image3 : './assets/img/nails-v-2.webp', id : "nails"},

             ];
             
