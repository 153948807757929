import React  from 'react';
import {Carousel} from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
//import Slide1 from './assets/img/slide-1.webp';
import Slide2 from './assets/img/slide-2.webp';
import Slide3 from './assets/img/slide-3.webp';
import Slide1 from './assets/img/slide-v1.webp';

function TpCarousel() {
    
  
      return (
          <Carousel>
            <Carousel.Item>
              <Image
                className="d-block w-100 puff-in-center"
                src={Slide1}
                alt="First slide"
                />
              <Carousel.Caption>
                
                <h1 className="text-left" style = {{color : 'black'}}><strong>Roofing supplies </strong></h1>
                <p className="text-left" style = {{paddingBottom: '10vw'}}>Get A Roof And Peace Of Mind</p>
                {/* <button className = 'btn btn-primary'>Read More</button> */}
              
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={900}>
              <Image
                className=" puff-in-center d-block w-100"
                src={Slide2}
                alt="Third slide"
              />
              <Carousel.Caption>
                
                <h1 style = {{color : 'black'}}><strong>You can trust us </strong></h1>
                <p style = {{paddingBottom: '10vw'}}>With your construction projects</p>
                {/* <button className = 'btn btn-primary'>View Details</button> */}
                
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Image  className=" puff-in-center d-block w-100"
                src={Slide3}
                alt="Third slide" />
              <Carousel.Caption>
                
                <h1 className="text-right" style = {{ color : 'black'}}><strong>Quality Building</strong></h1>
                <p className="text-right" style = {{paddingBottom: '8vw'}}>Needs a committed supplier of quality materials</p>
                {/* <button className = 'btn btn-primary'>More</button> */}
                
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
       );
    }

export default TpCarousel;
