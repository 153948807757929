import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";

const SubNav = () => {
  return (
    <Navbar sticky="top" style={{ top: "85px"}}>
      <Nav className="mr-auto alignment">
      <Link className="subnavbarLink middle" to="/steel">
          Steel
        </Link>
        <Link className="subnavbarLink middle" to="/sheets">
          Sheets
        </Link>
        <Link className="subnavbarLink middle" to="/cement">
          Cement
        </Link>
        <Link className="subnavbarLink middle" to="/tiles-S">
          Tiles & Sanitaries
        </Link>
        <Link className="subnavbarLink middle" to="/hardware">
          Hardwares
        </Link>
      </Nav>
    </Navbar>
  );
};

export default SubNav;
