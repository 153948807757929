import React from "react";
import { Image } from "react-bootstrap";

function SubProduct({ products }) {
  return (
    <>
      <div className="container" style={{ marginTop: "5rem" }}>
        <div className="row">
          {products.map((product) => (
            <div id={product.id} class="col-lg-6">
              <div >
                <h2 style={{ marginTop: "auto" }}>{product.name}</h2>

                <Image style={{padding:"4px"}}
                  src={product.image1}
                  alt="logo img"
                  width="100"
                  height="100"
                />

                <Image style={{padding:"4px"}}
                  src={product.image2}
                  alt="logo img"
                  width="100"
                  height="100"
                />

                <Image style={{padding:"4px"}}
                  src={product.image3}
                  alt="logo img"
                  width="100"
                  height="100"
                />
              </div>
              <p></p>
              <h6 style={{ display: product.thickness ? "block" : "none" }}>
                Thickness : {product.thickness}
              </h6>
              <h6 style={{ display: product.width ? "block" : "none" }}>
                Width : {product.width}
              </h6>
              <h6
                style={{
                  display: product.size ? "block" : "none",
                  whiteSpace: product.size ? "block" : "normal",
                }}
              >
                Size : {product.size}
              </h6>
              <h6 style={{ display: product.length ? "block" : "none" }}>
                Length : {product.length}
              </h6>
              <div style={{fontStyle:'italic'}}>
              <h6 style={{ display: product.color ? "block" : "none" }}>
                Available Colors : {product.color}
                </h6>
                <h6 style={{ display: product.style ? "block" : "none" }}>
                * {product.style} *
                </h6>
            </div>
              <hr />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default SubProduct;
