import React from "react";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import HrSheet from "./assets/img/hrsheet.webp";
import Cement from "./assets/img/cement.webp";
import SteelImg from "./assets/img/steel.webp";
import HardwareImg from "./assets/img/otherproduct.webp";
import Header from "./header";
import About from "./about";
import Footer from "./footer";
import Tiles from "./assets/img/tiles.webp";

const Product = () => {
  return (
    <>
      <Header />
      <div className="container">
      </div>
      <div className="container">
        <h1 className="prod-heading ">
          <b>Product Categories</b>
        </h1>
        <div className="row featurette">
          <div className="col-md-7">
            <h2 className="featurette-heading">Steel</h2>
            <p className="lead"></p>
            <p>
              <Link class="btn btn-outline-dark" to="/steel" role="button">
                View more &raquo;
              </Link>
            </p>
          </div>
          <div className="col-md-5">
            <title>Placeholder</title>
            <rect width="100%" height="100%" fill="#ffffff" />
            <Image src={SteelImg} alt="tool-img" width="100%" height="100%" />
          </div>
        </div>

        <hr className="featurette-divider" />

        <div className="row featurette">
          <div className="col-md-7 order-md-2">
            <h2 className="featurette-heading">Sheets</h2>
            <p className="lead"></p>
            <p>
              <Link class="btn btn-outline-dark" to="/sheets" role="button">
                View more &raquo;
              </Link>
            </p>
          </div>
          <div className="col-md-5 order-md-1">
            <title>Placeholder</title>
            <rect width="100%" height="100%" fill="#ffffff" />
            <Image src={HrSheet} alt="tool-img" width="100%" height="100%" />
          </div>
        </div>

        <hr className="featurette-divider" />

        <div className="row featurette">
          <div className="col-md-7">
            <h2 className="featurette-heading">Cement</h2>
            <p className="lead"></p>
            <p>
              <Link class="btn btn-outline-dark" to="/cement" role="button">
                View more &raquo;
              </Link>
            </p>
          </div>
          <div className="col-md-5">
            <title>Placeholder</title>
            <rect width="100%" height="100%" fill="#ffffff" />
            <Image src={Cement} alt="tool-img" width="100%" height="100%" />
          </div>
        </div>

        <hr className="featurette-divider" />

        <div className="row featurette">
          <div className="col-md-7 order-md-2">
            <h2 className="featurette-heading">Tiles & Saniteries</h2>
            <p className="lead"></p>
            <p>
              <Link class="btn btn-outline-dark" to="/tiles-S" role="button">
                View more &raquo;
              </Link>
            </p>
          </div>
          <div className="col-md-5 order-md-1">
            <rect width="100%" height="100%" fill="#ffffff" />
            <Image src={Tiles} alt="tool-img" width="100%" height="100%" />
            <title>Placeholder</title>
          </div>
        </div>

        <hr className="featurette-divider" />

        <div className="row featurette">
          <div className="col-md-7">
            <h2 className="featurette-heading">Hardwares</h2>
            <p className="lead"></p>
            <p>
              {" "}
              <Link class="btn btn-outline-dark" to="/hardware" role="button">
                View more &raquo;
              </Link>
            </p>
          </div>
          <div className="col-md-5">
            <title>Placeholder</title>
            <rect width="100%" height="100%" fill="#ffffff" />
            <Image
              src={HardwareImg}
              alt="tool-img"
              width="100%"
              height="100%"
            />
          </div>
        </div> 
      </div>
      <hr className="featurette-divider" />
      <About />
      <Footer />
    </>
  );
};

export default Product;
