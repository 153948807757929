import React from "react";
import Footer from "../footer";
import Header from "../header";
import SubNav from "../subnav";
import "../assets/css/product.css";
import SubProduct from "../subproduct";
import MultiCarousel from "../multicarousel";
import { categoryhr} from "../data";
import { hrproducts,crproducts,preproducts,galvanproducts,polycorbproducts,accessproducts } from "../data";

function HotRoll() {
  return (
    <>
      <Header />
      <div className="container" style={{ marginTop: "40px" }}>
        <h2 style={{ textAlign: "center", padding: "5px" , textDecoration:"underline"}}>
          <b>SHEETS</b>
        </h2>
        <MultiCarousel items={categoryhr} />
      </div>
      <SubNav />
      <h2 style={{ textAlign: "center", padding: "5px" , textDecoration:"underline"}}>
          <b>HR SHEET</b>
        </h2>
      <SubProduct products={hrproducts} />

      <div className="container" style={{ marginTop: "40px" }}>
        <h2 style={{ textAlign: "center", padding: "5px" , textDecoration:"underline"}}>
          <b>CR SHEET</b>
        </h2>
        {/* <MultiCarousel items={categoryhr} /> */}
      </div>
      {/* <SubNav /> */}
      <SubProduct products={crproducts} />

      <div className="container" style={{ marginTop: "40px" }}>
        <h2 style={{ textAlign: "center", padding: "5px" , textDecoration:"underline"}}>
          <b>PRE PAINTED</b>
        </h2>
        {/* <MultiCarousel items={categoryhr} /> */}
      </div>
      {/* <SubNav /> */}
      <SubProduct products={preproducts} />

      <div className="container" style={{ marginTop: "40px" }}>
        <h2 style={{ textAlign: "center", padding: "5px" , textDecoration:"underline"}}>
          <b>GALAVANISED</b>
        </h2>
      </div>
      <SubProduct products={galvanproducts} />

      <div className="container" style={{ marginTop: "40px" }}>
        <h2 style={{ textAlign: "center", padding: "5px" , textDecoration:"underline"}}>
          <b>ACCESSORIES</b>
        </h2>
        {/* <MultiCarousel items={categoryhr} /> */}
      </div>
      {/* <SubNav /> */}
      <SubProduct products={accessproducts} />


      

      <div className="container" style={{ marginTop: "40px" }}>
        <h2 style={{ textAlign: "center", padding: "5px" , textDecoration:"underline"}}>
          <b>POLYCARBONATES</b>
        </h2>
        {/* <MultiCarousel items={categoryhr} /> */}
      </div>
      {/* <SubNav /> */}
      <SubProduct products={polycorbproducts} />
      <Footer />
    </>
  );
}

export default HotRoll;
