import React from "react";
import LockImg from "./assets/img/service-images/lock.webp";
import DurableImg from "./assets/img/service-images/durable.webp";
import CostImg from "./assets/img/service-images/cost.webp";
import QualityImg from "./assets/img/service-images/quality.webp"
import RelationImg from "./assets/img/service-images/relation.webp"
import BrandImg from "./assets/img/service-images/brand.webp"

const Services = () => {
  return (
    <main role="main" className="container">
       <hr className="featurette-divider" />
       <h1 className="prod-heading " style={{marginBottom:"15px"}}>
          <b>SERVICES</b>
        </h1>
      <div className="container marketing">
        <div className="row">
          <div className="col-lg-4">
            <svg
            style={{marginLeft:"3vw"}}
              className="bd-placeholder-img"
              width="140"
              height="140"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
              role="img"
              aria-label="Placeholder: 140x140"
            >
              <image href={LockImg} alt="Tool-img" width="120" height="120" />
              <title>Placeholder</title>
            </svg>
            <h2>Extremely Safe </h2>
            <p>
              Our products have been designed with utmost care and to
              perfection. Adding to this our stringent quality check makes our
              products extremely safe.
            </p>
          </div>
          <div className="col-lg-4">
            <svg
            style={{marginLeft:"3vw"}}
              className="bd-placeholder-img"
              width="140"
              height="140"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
              role="img"
              aria-label="Placeholder: 140x140"
            >
              <image href={DurableImg} alt="Tool-img" width="80%" hight="80%" />
              <title>Placeholder</title>
            </svg>
            <h2>Durable</h2>
            <p>
              All products are designed and built in a manner that enhances
              their longevity and thus makes them immensely durable.
            </p>
          </div>
          <div className="col-lg-4">
            <svg
            style={{marginLeft:"3vw"}}
              className="bd-placeholder-img rounded-rectangle"
              width="140"
              height="140"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
              role="img"
              aria-label="Placeholder: 140x140"
            >
              <image href={CostImg} alt="Tool-img" width="80%" hight="80%" />
              <title>Placeholder</title>
            </svg>
            <h2>Effective Cost</h2>
            <p>
              Keeping in mind the need to save money we have designed methods
              that make installation of our products cost effective.
            </p>
          </div>
          <div className="col-lg-4">
            <svg
            style={{marginLeft:"3vw"}}
              className="bd-placeholder-img rounded-rectangle"
              width="140"
              height="140"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
              role="img"
              aria-label="Placeholder: 140x140"
            >
              <image href={QualityImg} alt="Tool-img" width="80%" hight="80%" />
              <title>Placeholder</title>
            </svg>
            <h2>Quality</h2>
            <p>
              Master of Consistancy and Quality.
              <p>We are the most liked organization in our business.</p>
            </p>
          </div>
          <div className="col-lg-4">
            <svg
            style={{marginLeft:"3vw"}}
              className="bd-placeholder-img rounded-rectangle"
              width="140"
              height="140"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
              role="img"
              aria-label="Placeholder: 140x140"
            >
              <image href={RelationImg} alt="Tool-img" width="80%" hight="80%" />
              <title>Placeholder</title>
            </svg>
            <h2>Relation</h2>
            <p>
              Our Image is Part Of Our Reputation.
              <p>So we assure about your's also, helping you and your house become better Acquainted </p> 
            </p>
          </div>
          <div className="col-lg-4">
            <svg
            style={{marginLeft:"3vw"}}
              className="bd-placeholder-img rounded-rectangle"
              width="140"
              height="140"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
              role="img"
              aria-label="Placeholder: 140x140"
            >
              <image href={BrandImg} alt="Tool-img" width="80%" hight="80%" />
              <title>Placeholder</title>
            </svg>
            <h2>Brand</h2>
            <p>
            We deal in the brands you have been trusting for years. 
            </p>
          </div>

          <hr className="featurette-divider" />
        </div>
      </div>
    </main>
  );
};

export default Services;
