import React from "react";
import Header from "./header";
import TpCarousel from "./carousel";
import Services from "./services";
import Footer from "./footer";
import About from "./about";
import Slider from "./slider";
import "popper.js";

import Brand from "./brand";

const Home = () => {
  return (
    <div>
      <Header />
      <TpCarousel />
      <About />
      <Services />
      <Slider />
      <Brand />
      <Footer />
    </div>
  );
};

export default Home;
