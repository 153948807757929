import React from 'react';
import Footer from '../footer';
import Header from '../header';
import SubNav from '../subnav';
import '../assets/css/product.css';
import SubProduct from '../subproduct';
import MultiCarousel from '../multicarousel';
import {categorytiles} from '../data';
import {tilesproducts} from '../data';
              
    function Roof() {
                

    return (
        <>
            <Header />
            <div className="container" style={{marginTop : "40px"}}>
            <h2 style={{textAlign : "center",padding:"5px", textDecoration:"underline" }}><b>TILES & SANITERIES</b></h2>
            <MultiCarousel items={categorytiles}/>
            </div>
            <SubNav />
             {/* <div className="container" style={{textAlign:"center",paddingTop:"2rem",backgroundColor:'rgb(241, 231, 231)' , borderRadius: '0px 0px 5px 5px'}}>
          
          <h5>Welcome to Surabhi Steel House.</h5>
          <p>
            We Trade in Wall tile, Vitrified Tiles, Floor Tiles and Sanitary
            Wares in different sizes Like -
          </p>
          <div className="row">
            <div className="cardcolumn">
              <h4>Wall Tiles</h4>
              <li>250*375mm</li>
              <li>300*450mm</li>
              <li>300*600mm</li>
            </div>
            <div className="cardcolumn">
              <h4>Floor and Parking Tiles</h4>
              <li>300*300mm</li>
              <li>400*400mm</li>
            </div>
            <div className="cardcolumn">
              <h4>Vertified/Porcelain/Double Charge/GVT/PGVT</h4>
              <li>600*600mm</li>
              <li>800*880mm</li>
              <li>600*1200mm</li>
              <li>800*1600mm</li>
            </div>
          </div>
        </div> */}
            <SubProduct products = {tilesproducts} />
            <Footer />
        </>

    );

}

export default Roof;
