import React from "react";
import Footer from "../footer";
import Header from "../header";
import SubNav from "../subnav";
import "../assets/css/product.css";
import SubProduct from "../subproduct";
import MultiCarousel from "../multicarousel";
import { categorycement } from "../data";
import { cementproducts } from "../data";

function Cement() {
  return (
    <>
      <Header />
      <div className="container" style={{ marginTop: "40px" }}>
        <h2 style={{ textAlign: "center", padding: "5px", textDecoration:"underline" }}>
          <b>CEMENT ARTICLES</b>
        </h2>
        <MultiCarousel items={categorycement} />
      </div>
      <SubNav />
      <SubProduct products={cementproducts} />
      <Footer />
    </>
  );
}

export default Cement;
