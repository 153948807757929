import React, { useState } from "react";
import Logo from "./assets/img/logo.webp";
import { HashLink as Link } from "react-router-hash-link";
import { Navbar, Form, Nav } from "react-bootstrap";

const Header = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar
      expand="lg"
      expanded={expanded}
      fixed="top"
      variant="dark"
      style={{ backgroundColor: "rgb(0, 17, 88)" }}
      
    >
      <Navbar.Brand href="http://surabhisteel.com/">
        <img src={Logo} alt="logo" width="100" height="60" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")}/>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto middle">
          <Link
            to="/"
            className="nav-link line current middle"
            onClick={() => setExpanded(false)}
          >
            Home
          </Link>
          <Link
            to="/product"
            className="nav-link line middle"
            onClick={() => setExpanded(false)}
          >
            Product
          </Link>
          <Link
            to="/#about"
            className="nav-link line current middle"
            onClick={() => setExpanded(false)}
          >
            About
          </Link>
          <Link
            to="#contact"
            className="nav-link line middle"
            onClick={() => setExpanded(false)}
          >
            Contact
          </Link>
        </Nav>
        <Form inline>
          <span style={{ fontSize: "16px", color: "white" }}>
            <i className="fa fa-phone"></i>&emsp;+91 9448864599
          </span>
          &emsp;
          <a
            href="https://www.google.com/maps/place/16%C2%B028'14.3%22N+75%C2%B006'29.8%22E/@16.470626,75.1060923,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x0!8m2!3d16.470626!4d75.108281"
            className="nav-link line current middle"
          >
            <i className="fa fa-map"></i>&emsp; Reach Us
          </a>
        </Form>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
